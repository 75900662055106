// import Vue from "vue";

export default {
  methods: {
    decodeQRcode(qrString, restQuestions = []) {
      const code = qrString.split("?");
      const answerData = {
        examId: code.shift(),
        uid: code.shift(),
      };
      this.$store.state.defaultQuestions.forEach((dq) => {
        answerData[dq.value] = decodeURIComponent(code.shift().slice(1));
      });
      if (restQuestions.length === 0) {
        return answerData;
      }
      answerData.restAnswers = [];
      for (let i = 0; i < restQuestions.length; i += 1) {
        const add = {
          text: restQuestions[i].text,
          memo: restQuestions[i].memo,
          tf: Number(code[i].slice(0, 1)),
          memoAnswer: decodeURIComponent(code[i].slice(1)),
        };
        answerData.restAnswers.push(add);
      }
      return answerData;
    },
  },
};
